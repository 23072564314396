import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { updateGeoService } from "../services/geolocation";
import { getSiteService } from "../services/site";
import { loginAuth } from "../services/auth";
import { checkAuth } from "../services/auth";
import { message, Input, Modal } from "antd";
import { KeyOutlined } from "@ant-design/icons";
import KalmanFilter from "kalmanjs";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const Init = () => {
  let query = useQuery();
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [device_uuid, setDevice_uuid] = useState("");
  const [locationData, setLocationData] = useState(null);

  const latFilter = new KalmanFilter({ R: 0.01, Q: 3 });
  const lonFilter = new KalmanFilter({ R: 0.01, Q: 3 });

  useEffect(() => {
    if (!query.get("device") && !localStorage.getItem("device")) {
      setIsModalOpen(true);
      localStorage.clear();
    } else {
      setDevice_uuid(query.get("device"));
    }

    const geoWatchId = navigator.geolocation.watchPosition(
      (position) => {
        const rawLat = position.coords.latitude;
        const rawLon = position.coords.longitude;
        const filteredLat = latFilter.filter(rawLat);
        const filteredLon = lonFilter.filter(rawLon);

        // ตรวจจับค่ากระโดดผิดปกติ
        if (
          locationData &&
          haversine(locationData.lat, locationData.lon, filteredLat, filteredLon) > 50
        ) {
          console.warn("Detected GPS Jump, Ignoring Data");
          return;
        }

        const newLocation = { lat: filteredLat, lon: filteredLon };
        setLocationData(newLocation);
        localStorage.setItem("location", JSON.stringify(newLocation));
      },
      (error) => console.warn(`ERROR(${error.code}): ${error.message}`),
      options
    );

    checkAuth().then((res) => {
      if (!res) {
        messageApi.open({
          type: "error",
          content: "กรอก Access Key เพื่อเข้าสู่ระบบ",
        });
        localStorage.clear();
        setIsModalOpen(true);
      } else {
        messageApi.open({
          type: "success",
          content: "Authentication successful",
        });
      }
    });

    return () => navigator.geolocation.clearWatch(geoWatchId);
  }, []);

  useEffect(() => {
    const updateGeo = setInterval(() => {
      if (locationData && localStorage.getItem("device")) {
        const deviceData = JSON.parse(localStorage.getItem("device"));
        updateGeoService({
          site_uuid: deviceData[1],
          device_uuid: deviceData[2],
          lat: locationData.lat,
          long: locationData.lon,
        });
        console.log(`Updated Location: ${locationData.lat}, ${locationData.lon}`);
      }
    }, 15 * 1000);

    return () => clearInterval(updateGeo);
  }, [locationData]);

  const handleOk = () => {
    if (device_uuid) {
      loginAuth(device_uuid.replace(/\s+/g, " ").trim()).then(async (res) => {
        if (res) {
          await getSiteService(res[0]["site_uuid"]).then((res) => {
            localStorage.setItem("site", res[0].site_name);
          });
          localStorage.setItem(
            "device",
            JSON.stringify([res[0]["account_uuid"], res[0]["site_uuid"], res[0]["device_uuid"]])
          );
          setIsModalOpen(false);
          window.location.reload();
        } else {
          messageApi.open({
            type: "error",
            content: "Access Key เพื่อเข้าสู่ระบบไม่ถูกต้อง",
          });
          setIsModalOpen(true);
        }
      });
    }
  };

  return (
    <>
      <div>{contextHolder}</div>
      <Modal title="กรอก Access Key เพื่อเข้าสู่ระบบ" open={isModalOpen} onOk={handleOk}>
        <Input
          onChange={(e) => setDevice_uuid(e.target.value)}
          value={device_uuid}
          size="large"
          placeholder="Token"
          prefix={<KeyOutlined />}
        />
      </Modal>
    </>
  );
};

// ฟังก์ชันคำนวณระยะห่างระหว่าง 2 จุดบนโลกโดยใช้ Haversine Formula
function haversine(lat1, lon1, lat2, lon2) {
  const R = 6371e3; // Earth radius in meters
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

export default Init;