import React, { useState, useEffect } from "react";
import { Button, Modal, Upload, Alert, Input, Select } from "antd";
import { setReport, updateReport } from "../../services/daily";
import moment from "moment";
import { uploadImageFileService } from "../../services/upload";
import Init from "../../components/InitPatrol";

export default function InputApp() {
  const [meta, setMeta] = useState("");
  const [type, setType] = useState("เลือกประเภทรายงาน");
  const [name, setName] = useState("");
  const [author, setAuthor] = useState("");
  const [location, setLocation] = useState("");
  const [detail, setDetail] = useState("");
  const [fileImage, setFileImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const type0 = `
    รายงานปัญหาอื่นๆ
    เรื่อง : ${name}
    ผู้เขียนรายงาน : ${author}
    วันที่ : ${moment().format("DD/MM/YYYY")}
    เวลา : ${moment().format("HH:mm")}
    สถานที่ : ${location}
    รายละเอียด :
      ${detail}
  `;

  const type1 = `
    รายงานกำลังพล
    เรื่อง : ${name}
    ผู้เขียนรายงาน : ${author}
    วันที่ : ${moment().format("DD/MM/YYYY")}
    เวลา : ${moment().format("HH:mm")}
    สถานที่ : ${location}
    รายละเอียด :
      ${detail}
  `;

  const type2 = `
    รายงานการตรวจกล้อง CCTV
    เรื่อง : ${name}
    ผู้เขียนรายงาน : ${author}
    วันที่ : ${new Date().toLocaleDateString()}
    เวลา : ${new Date().toLocaleTimeString()}
    สถานที่ : ${location}
    รายละเอียด :
      ${detail}
  `;

  const type3 = `
    รายงานอุปกรณ์
    เรื่อง : ${name}
    ผู้เขียนรายงาน : ${author}
    วันที่ : ${new Date().toLocaleDateString()}
    เวลา : ${new Date().toLocaleTimeString()}
    สถานที่ : ${location}
    รายละเอียด :
      ${detail}
  `;

  const type4 = `
    รายงานสรุปจำนวนผู้มาติดต่อ
    เรื่อง : ${name}
    ผู้เขียนรายงาน : ${author}
    วันที่ : ${new Date().toLocaleDateString()}
    เวลา : ${new Date().toLocaleTimeString()}
    สถานที่ : ${location}
    รายละเอียด : 
      ${detail}
  `;

  const type5 = `
    รายงานการปฏิบัติงานพลัดกลางวัน
    เรื่อง : ${name}
    ผู้เขียนรายงาน : ${author}
    วันที่ : ${new Date().toLocaleDateString()}
    เวลา : ${new Date().toLocaleTimeString()}
    สถานที่ : ${location}
    รายละเอียด : 
      ${detail}
  `;

  const type6 = `
    การปฏิบัติงานพลัดกลางคืน
    เรื่อง : ${name}
    ผู้เขียนรายงาน : ${author}
    วันที่ : ${new Date().toLocaleDateString()}
    เวลา : ${new Date().toLocaleTimeString()}
    สถานที่ : ${location}
    รายละเอียด : 
      ${detail}
  `;

  const dummyRequest = ({ file, onSuccess }) => {
    setFileImage(file);

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handSubmit = async () => {
    if (isSubmitting) return;
    if (!fileImage || !name || !author || !location || !detail) {
      alert("กรุณากรอกข้อมูลให้ครบถ้วนและอัปโหลดภาพ");
      return;
    }

    setIsSubmitting(true);

    const payload = {
      site_uuid: JSON.parse(localStorage.getItem("device"))[1],
      meta: meta,
      type: type["label"],
      name: name,
      author: author,
      lat: JSON.parse(localStorage.getItem("location"))?.lat,
      long: JSON.parse(localStorage.getItem("location"))?.lon,
    };
    const data = await setReport(payload);
    if (data.length > 0) {
      await updateReport(data[0].id, {
        image: `report/${JSON.parse(localStorage.getItem("device"))[1]}/${
          data[0].id
        }.jpg`,
      });
      await uploadImageFileService(
        fileImage,
        "siamai",
        `report/${JSON.parse(localStorage.getItem("device"))[1]}/${
          data[0].id
        }.jpg`
      );
    }
    setMeta("");
    setType("เลือกประเภทรายงาน");
    setName("");
    setAuthor("");
    setLocation("");
    setDetail("");
    setFileImage(null);
    setIsSubmitting(false);
    window.location.reload();
  };

  useEffect(() => {
    setMeta(type0 || type1 || type2 || type3 || type4 || type5 || type6);
  }, [type0, type1, type2, type3, type4, type5, type6]);

  useEffect(() => {
    setMeta(meta);
  }, [meta]);

  return (
    <div>
      <Init />
      <h1>การบันทึกรายงานประจำวัน</h1>
      <Select
        className="mt-2"
        defaultValue="เลือกประเภทรายงาน"
        style={{
          width: "100%",
        }}
        value={type}
        onChange={(value, label) => {
          setMeta(value);
          setType(label);
        }}
        options={[
          {
            value: type0,
            label: "ปัญหาอื่นๆ",
          },
          {
            value: type1,
            label: "กำลังพล",
          },
          {
            value: type2,
            label: "การตรวจกล้อง CCTV",
          },
          {
            value: type3,
            label: "อุปกรณ์",
          },
          {
            value: type4,
            label: "สรุปจำนวนผู้มาติดต่อ",
          },
          {
            value: type5,
            label: "การปฏิบัติงานพลัดกลางวัน",
          },
          {
            value: type6,
            label: "การปฏิบัติงานพลัดกลางคืน",
          },
        ]}
      />
      <Input
        className="mt-2"
        addonBefore="ผู้เขียนรายงาน"
        defaultValue=""
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
      />
      <Input
        className="mt-2"
        addonBefore="เรื่อง"
        defaultValue=""
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        className="mt-2"
        addonBefore="สถานที่"
        defaultValue=""
        value={location}
        onChange={(e) => setLocation(e.target.value)}
      />
      <Input
        className="mt-2"
        addonBefore="รายละเอียด"
        defaultValue=""
        value={detail}
        onChange={(e) => setDetail(e.target.value)}
      />

      <Upload
        className="mt-2"
        customRequest={dummyRequest}
        fileList={
          fileImage
            ? [
                {
                  uid: "-1",
                  name: "image.jpg",
                  status: "done",
                  url: fileImage ? URL.createObjectURL(fileImage) : "",
                },
              ]
            : []
        }
        listType="picture-card"
        maxCount={1}
        multiple
        accept="image/x-png,image/jpeg"
        capture="environment"
      >
        {"+ ถ่ายภาพ"}
      </Upload>

      <Input.TextArea
        className="mt-2"
        showCount
        style={{
          height: 250,
        }}
        value={meta}
        onChange={(e) => setMeta(e.target.value)}
        placeholder=""
      />

      <div className="grid grid-cols mt-4 items-center justify-center">
        <Button
          className="w-[320px] h-[55px] bg-[green] text-[24px] mt-4 text-[#FFFFFF]"
          onClick={handSubmit}
        >
          บันทึก
        </Button>

        <Button
          className="w-[320px] h-[55px] bg-[#222222] text-[24px] mt-4 text-[#FFFFFF]"
          onClick={() => {
            setMeta("");
            setType("เลือกประเภทรายงาน");
            setName("");
            setAuthor("");
            setLocation("");
            setDetail("");
            setFileImage(null);
          }}
        >
          ล้างข้อมูล
        </Button>
      </div>
    </div>
  );
}
