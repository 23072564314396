import React, { useState, useEffect } from "react";
import { getCheckpointByCheckpointService } from "../../../services/checkpoint";
import { setPatrolService } from "../../../services/patrol";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Modal, Radio, Upload, Input } from "antd";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  CameraOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { uploadImageService, uploadLog } from "../../../services/upload";
import Init from "../../../components/InitPatrol";
import { message } from "antd";
import Webcam from "react-webcam";

const { TextArea } = Input;

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function InputApp() {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    // account_uuid: JSON.parse(localStorage.getItem("device"))[0],
    site_uuid: JSON.parse(localStorage.getItem("device"))[1],
    device_uuid: JSON.parse(localStorage.getItem("device"))[2],
    report_name: "",
    report_where: "",
    report_time: "",
    report_who: "",
    report_status: "",
    report_message: "",
    report_lat: JSON.parse(localStorage.getItem("location"))?.lat,
    report_long: JSON.parse(localStorage.getItem("location"))?.lon,
    report_img: [],
  });
  const [question, setQuestion] = useState([]);

  const [key, setKey] = useState("");
  const navigate = useNavigate();

  const [listening, setListening] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();

  const site_uuid = JSON.parse(localStorage.getItem("device"))[1];
  const [messageApi, contextHolder] = message.useMessage();
  let query = useQuery();

  const [imgSrc, setImgSrc] = useState([]);
  const webcamRef = React.useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const toggleListeningHandler = () => {
    if (!listening) {
      SpeechRecognition.startListening({
        continuous: true,
        language: "th-TH",
      });
    } else {
      resetTranscript();
      SpeechRecognition.stopListening();
    }
    setListening((prevState) => !prevState);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    uploadImageService(
      file,
      "patrol",
      `${site_uuid}/${new Date().getTime()}.jpg`,
      data.checkpoint_name
        ? data.checkpoint_name
        : `lat: ${JSON.parse(localStorage.getItem("location"))?.lat}, lon: ${JSON.parse(localStorage.getItem("location"))?.lon
        }`
    ).then((res) => {
      setImage((prev) => [...prev, res]);
      payload.report_img.push(res.fullPath);
      uploadLog({
        path: res.fullPath,
      });
      onSuccess("ok");
    });
  };

  const handleChange = (item, value) => {
    setQuestion((prev) => {
      const existingQuestionIndex = prev.findIndex((q) => q.question === item);
      if (existingQuestionIndex !== -1) {
        const updatedQuestions = [...prev];
        updatedQuestions[existingQuestionIndex] = {
          question: item,
          answer: value,
        };
        return updatedQuestions;
      } else {
        return [...prev, { question: item, answer: value }];
      }
    });
  };

  useEffect(() => {
    if (transcript !== "") {
      payload[key] = transcript;
      setPayload({ ...payload });
    }
  }, [key, transcript]);

  useEffect(() => {
    console.log(question);
  }, [question]);

  useEffect(() => {
    if (query.get("checkpoint") !== "abnormal") {
      getCheckpointByCheckpointService(query.get("checkpoint")).then((res) => {
        setData(res);
        setPayload({ ...payload, checkpoint_name: res.checkpoint_name });
      });
    } else {
      setMode("abnormal");
      setPayload({
        ...payload,
        checkpoint_name: `${JSON.parse(localStorage.getItem("location"))?.lat},${JSON.parse(localStorage.getItem("location"))?.lon
          }`,
        report_status: "abnormal",
      });
    }
  }, []);

  useEffect(() => {
    if (data?.checkpoint_question?.length > 0) {
      setPayload({
        ...payload,
        report_message:
          "\n" +
          question
            .map(
              (item) =>
                `${item.question} ${item.answer === "ปกติ" ? "✅" : "❌"}\n`
            )
            .join(""),
      });
    }
  }, [question]);

  const capture = React.useCallback(() => {
    if (imgSrc.length >= 5) {
      messageApi.warning('สามารถถ่ายได้สูงสุด 5 ภาพ');
      return;
    }

    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(prev => [...prev, imageSrc]);

    // Convert base64 to blob
    const blob = dataURLtoBlob(imageSrc);
    const file = new File([blob], `capture-${Date.now()}.jpg`, { type: 'image/jpeg' });

    dummyRequest({
      file,
      onSuccess: () => console.log('Capture uploaded successfully')
    });
  }, [webcamRef, imgSrc.length]);

  const clearImage = (index) => {
    setImgSrc(prev => prev.filter((_, i) => i !== index));
  };

  // Helper function to convert base64 to blob
  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const cameraModal = (
    <Modal
      title="ถ่ายภาพ (สูงสุด: 5 ภาพ)"
      open={open}
      onOk={handleClose}
      onCancel={handleClose}
      width={600}
      footer={null}
    >
      <div className="grid grid-cols-1 gap-2">
        <div className="text-center">
          {imgSrc.length > 0 && (
            <div className="mt-2">
              <h4 className="mb-1">ภาพที่ถ่าย ({imgSrc.length}/5)</h4>
              <div className="grid grid-cols-5 gap-1">
                {imgSrc.map((src, index) => (
                  <div key={index} className="relative">
                    <img
                      src={src}
                      alt={`capture-${index}`}
                      className="w-full h-[60px] object-cover rounded"
                    />
                    <Button
                      danger
                      type="primary"
                      size="small"
                      onClick={() => clearImage(index)}
                      className="absolute top-0 right-0 min-w-0 w-5 h-5 p-0 flex items-center justify-center"
                    >
                      ×
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <Webcam
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: "environment"
            }}
            style={{ width: '100%', height: '300px', objectFit: 'cover' }}
          />
          <Button
            onClick={capture}
            type="primary"
            className="mt-2"
            disabled={imgSrc.length >= 5}
          >
            ถ่ายภาพ
          </Button>
        </div>



        <div className="text-center mt-2">
          <Button type="primary" onClick={handleClose}>
            ตกลง
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <div className="text-center">
      <Init />
      {contextHolder}
      <h1 className="mb-0">{data?.checkpoint_name}</h1>
      <h4 className="mt-0">{data?.checkpoint_note}</h4>

      <Button
        className={`w-[320px] h-[55px] ${payload.report_img.length > 0 ? "bg-[#31A627]" : "bg-[#00204A]"
          } text-[24px]`}
        icon={<CameraOutlined />}
        onClick={() => setOpen(!open)}
        type="primary"
        size="large"
      >
        ถ่ายภาพ
      </Button>

      {mode === "" && (
        <div className="flex items-center justify-center">
          <div className="grid grid-cols-1 gap-4 mt-[12px]">
            <p>เลือกประเภทเหตุการณ์</p>
            <Button
              className="w-[320px] h-[55px] bg-[#D8D8D8] text-[24px] mt-4 text-[#111111]"
              onClick={() => {
                setMode("normal");
                setPayload({ ...payload, report_status: "normal" });
              }}
              type="primary"
              size="large"
            >
              เหตุการณ์ทั่วไป
            </Button>

            <Button
              className="w-[320px] h-[55px] bg-[#D8D8D8] text-[24px] mt-4 text-[#111111]"
              onClick={() => {
                setMode("abnormal");
                setPayload({ ...payload, report_status: "abnormal" });
              }}
              type="primary"
              size="large"
            >
              เหตุการณ์ผิดปกติ
            </Button>
          </div>
        </div>
      )}

      {mode === "normal" && <h2 className="mb-0">รายงานเหตุการณ์ทั่วไป</h2>}

      {mode === "abnormal" && <h2 className="mb-0">เหตุการณ์ผิดปกติ</h2>}

      {mode === "normal" && data?.checkpoint_question.length === 0 && (
        <div className="gird grid-cols-2 space-x-2 items-center justify-center flex mt-[12px]">
          <TextArea
            className="w-[270px] h-[55px]"
            placeholder="รายละเอียด"
            value={payload.report_message}
            onChange={(e) =>
              setPayload({ ...payload, report_message: e.target.value })
            }
          />
          <Button
            className="w-[45px] h-[55px] text-[24px] text-center items-center justify-center flex bg-[#00204A]"
            onClick={() => {
              toggleListeningHandler();
              setKey("report_message");
            }}
            icon={<AudioOutlined />}
            type="primary"
            danger={listening}
            size="large"
          ></Button>
        </div>
      )}

      {mode === "normal" && data?.checkpoint_question.length > 0 && (
        <div className="gird grid-cols-1 space-x-2 items-center justify-center mt-[12px]">
          {data?.checkpoint_question.map((item) => (
            <div
              key={item.id}
              className="grid grid-cols-1 items-center justify-center"
            >
              <h3>{item}</h3>
              <Radio.Group
                onChange={(e) => handleChange(item, e.target.value)}
                defaultValue=""
                buttonStyle="solid"
              >
                <Radio.Button
                  style={{
                    backgroundColor:
                      question.filter((q) => q.question === item)[0]?.answer ===
                        "ปกติ"
                        ? "#31A627"
                        : "#D8D8D8",
                    color:
                      question.filter((q) => q.question === item)[0]?.answer ===
                        "ปกติ"
                        ? "#FFFFFF"
                        : "#31A627",
                  }}
                  value="ปกติ"
                >
                  ปกติ
                </Radio.Button>
                <Radio.Button
                  style={{
                    backgroundColor:
                      question.filter((q) => q.question === item)[0]?.answer ===
                        "ผิดปกติ"
                        ? "#FF0000"
                        : "#D8D8D8",
                    color:
                      question.filter((q) => q.question === item)[0]?.answer ===
                        "ผิดปกติ"
                        ? "#FFFFFF"
                        : "#FF0000",
                  }}
                  value="ผิดปกติ"
                >
                  ผิดปกติ
                </Radio.Button>
              </Radio.Group>
            </div>
          ))}
        </div>
      )}

      {mode === "abnormal" && (
        <div className="gird grid-cols-2 space-x-2 items-center justify-center flex mt-[12px]">
          <TextArea
            className="w-[270px] h-[55px]"
            placeholder="รายงานสถานการณ์"
            value={payload.report_name}
            onChange={(e) =>
              setPayload({ ...payload, report_name: e.target.value })
            }
          />
          <Button
            className="w-[45px] h-[55px] text-[24px] text-center items-center justify-center flex bg-[#00204A]"
            onClick={() => {
              toggleListeningHandler();
              setKey("report_name");
            }}
            icon={<AudioOutlined />}
            type="primary"
            danger={key === "report_name" && listening}
            size="large"
          ></Button>
        </div>
      )}

      {mode === "abnormal" && (
        <div className="gird grid-cols-2 space-x-2 items-center justify-center flex mt-[12px]">
          <TextArea
            className="w-[270px] h-[55px]"
            placeholder="สาเหตุ"
            value={payload.report_cause}
            onChange={(e) =>
              setPayload({ ...payload, report_cause: e.target.value })
            }
          />
          <Button
            className="w-[45px] h-[55px] text-[24px] text-center items-center justify-center flex bg-[#00204A]"
            onClick={() => {
              toggleListeningHandler();
              setKey("report_cause");
            }}
            icon={<AudioOutlined />}
            type="primary"
            danger={key === "report_cause" && listening}
            size="large"
          ></Button>
        </div>
      )}

      {mode === "abnormal" && (
        <div className="gird grid-cols-2 space-x-2 items-center justify-center flex mt-[12px]">
          <TextArea
            className="w-[270px] h-[55px]"
            placeholder="สถานที่เกิดเหตุ"
            value={payload.report_where}
            onChange={(e) =>
              setPayload({ ...payload, report_where: e.target.value })
            }
          />
          <Button
            className="w-[45px] h-[55px] text-[24px] text-center items-center justify-center flex bg-[#00204A]"
            onClick={() => {
              toggleListeningHandler();
              setKey("report_where");
            }}
            icon={<AudioOutlined />}
            type="primary"
            danger={key === "report_where" && listening}
            size="large"
          ></Button>
        </div>
      )}

      {mode === "abnormal" && (
        <div className="gird grid-cols-2 space-x-2 items-center justify-center flex mt-[12px]">
          <TextArea
            className="w-[270px] h-[55px]"
            placeholder="เวลาที่เกิดเหตุ"
            value={payload.report_time}
            onChange={(e) =>
              setPayload({ ...payload, report_time: e.target.value })
            }
          />
          <Button
            className="w-[45px] h-[55px] text-[24px] text-center items-center justify-center flex bg-[#00204A]"
            onClick={() => {
              toggleListeningHandler();
              setKey("report_time");
            }}
            icon={<AudioOutlined />}
            type="primary"
            danger={key === "report_time" && listening}
            size="large"
          ></Button>
        </div>
      )}

      {mode === "abnormal" && (
        <div className="gird grid-cols-2 space-x-2 items-center justify-center flex mt-[12px]">
          <TextArea
            className="w-[270px] h-[55px]"
            placeholder="ผู้รับผิดชอบหรือรับทราบ"
            value={payload.report_who}
            onChange={(e) =>
              setPayload({ ...payload, report_who: e.target.value })
            }
          />
          <Button
            className="w-[45px] h-[55px] text-[24px] text-center items-center justify-center flex bg-[#00204A]"
            onClick={() => {
              toggleListeningHandler();
              setKey("report_who");
            }}
            icon={<AudioOutlined />}
            type="primary"
            danger={key === "report_who" && listening}
            size="large"
          ></Button>
        </div>
      )}

      <div className="gird grid-cols-2 space-x-2 items-center justify-center flex mt-[12px]">
        <Button
          className="w-[240px] h-[55px] bg-[#00204A] text-[24px] mt-4"
          onClick={async () => {
            if (payload.report_img.length === 0) {
              messageApi.open({
                type: "error",
                content: "กรุณาถ่ายภาพ",
              });
              return;
            } else if (payload.report_message.trim() === "") {
              messageApi.open({
                type: "error",
                content: "กรุณากรอกรายละเอียด",
              });
              return;
            } else if (payload.report_status.trim() === "") {
              messageApi.open({
                type: "error",
                content: "กรุณาเลือกสถานะรายงาน",
              });
              return;
            } else {
              setPatrolService(payload).then(() => {
                setLoading(false);
                messageApi.open({
                  type: "success",
                  content: "รายงานสำเร็จ",
                });
                window.location.href = "/patrol";
              });
            }
          }}
          type="primary"
          loading={loading}
          size="large"
        >
          ยืนยัน
        </Button>
        <Button
          className="w-[75px] h-[55px] bg-[#00204A] text-[24px] mt-4"
          type="primary"
          size="large"
          onClick={() => {
            setMode("");
            setPayload({
              account_uuid: JSON.parse(localStorage.getItem("device"))[0],
              site_uuid: JSON.parse(localStorage.getItem("device"))[1],
              device_uuid: JSON.parse(localStorage.getItem("device"))[2],
              report_name: "",
              report_where: "",
              report_time: "",
              report_who: "",
              report_status: "",
              report_message: "",
              report_lat: JSON.parse(localStorage.getItem("location"))?.lat,
              report_long: JSON.parse(localStorage.getItem("location"))?.lon,
              report_img: [],
              checkpoint_name: data?.checkpoint_name,
            });
          }}
        >
          รีเซ็ต
        </Button>
      </div>

      {cameraModal}
    </div>
  );
}
