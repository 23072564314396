import React, { useState } from "react";
import { Modal, message, Button } from "antd";
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
import { getCheckpointByCheckpointService } from "../services/checkpoint";
import { getPatrolCycleBySite } from "../services/patrol";
import { useNavigate } from "react-router-dom";

const qrcodeRegionId = "html5qr-code-full-region";

const QrcodeComponent = ({ open, cancel, haversine }) => {
  const [data, setData] = useState({});
  const [title, setTitle] = useState("สแกนจุดตรวจ: ");
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const scannerRef = React.useRef(null);

  const cleanupScanner = () => {
    if (scannerRef.current) {
      scannerRef.current.clear().catch(error => {
        console.error("Failed to clear scanner", error);
      });

      // ทำความสะอาด camera resources ทั้งหมด
      try {
        // หยุดทุก tracks ที่กำลังทำงานอยู่
        navigator.mediaDevices.getUserMedia({ video: true })
          .then(stream => {
            stream.getTracks().forEach(track => track.stop());
          });

        // หยุด video elements ทั้งหมดที่อาจยังค้างอยู่
        const videoElements = document.getElementsByTagName('video');
        Array.from(videoElements).forEach(video => {
          if (video.srcObject) {
            const stream = video.srcObject;
            stream.getTracks().forEach(track => track.stop());
            video.srcObject = null;
          }
        });
      } catch (error) {
        console.error("Error cleaning up camera resources:", error);
      }

      scannerRef.current = null;
    }
  };

  const handleOk = () => {
    if (data?.checkpoint_uuid) {
      cleanupScanner();
      messageApi.open({
        type: "success",
        content: "เริ่มตรวจสอบจุดตรวจ " + data?.checkpoint_name,
      });
      navigate(
        "/patrol/v1?device=" +
        JSON.parse(localStorage.getItem("device"))[2] +
        "&checkpoint=" +
        data?.checkpoint_uuid
      );
      cancel();
    } else {
      messageApi.open({
        type: "error",
        content: "กรุณาสแกนจุดตรวจ",
      });
    }
  };

  const onScanSuccess = (decodedText) => {
    // Stop scanning immediately after successful read
    if (scannerRef.current) {
      scannerRef.current.pause();
    }

    getPatrolCycleBySite().then((res) => {
      console.log(res)
    })
    getCheckpointByCheckpointService(decodedText).then((res) => {
      if (!res) {
        messageApi.open({
          type: "error",
          content: "ไม่พบข้อมูลจุดตรวจ",
        });
        // Resume scanning if checkpoint is not found
        if (scannerRef.current) {
          scannerRef.current.resume();
        }
        return;
      }
      const location = JSON.parse(localStorage.getItem("location"));
      const { lat, lon } = location;
      if (res.checkpoint_lat && res.checkpoint_long) {
        const distance = haversine(
          res.checkpoint_lat,
          res.checkpoint_long,
          lat,
          lon
        );
        console.log(distance, res.checkpoint_distance_limit);
        if (
          distance > res.checkpoint_distance_limit &&
          res.checkpoint_distance_limit !== 0
        ) {
          messageApi.open({
            type: "error",
            content: "ไม่อยู่ในระยะที่กำหนด",
          });
          // Resume scanning if checkpoint is out of range
          if (scannerRef.current) {
            scannerRef.current.resume();
          }
          return;
        }
      }
      setData(res);
      setTitle("สแกนจุดตรวจ: " + res?.checkpoint_name);
      navigate(
        "/patrol/v1?device=" +
        JSON.parse(localStorage.getItem("device"))[2] +
        "&checkpoint=" +
        res?.checkpoint_uuid
      );
    });
  };

  const onScanError = (error) => {
    console.warn(`QR Code scan failed: ${error}`);
  };

  React.useEffect(() => {
    if (open) {
      const config = {
        fps: 10,
        qrbox: 250,
        disableFlip: false,
        aspectRatio: 1,
        rememberLastUsedCamera: true,
        hideSelectScanType: true,
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      };

      const html5QrcodeScanner = new Html5QrcodeScanner(
        qrcodeRegionId,
        config,
        /* verbose= */ false
      );

      scannerRef.current = html5QrcodeScanner;

      html5QrcodeScanner.render(onScanSuccess, onScanError);

      return () => {
        cleanupScanner();
      };
    }
  }, [open]);

  // เพิ่ม cleanup เมื่อ component unmount
  React.useEffect(() => {
    return () => {
      cleanupScanner();
    };
  }, []);

  return (
    <div>
      {contextHolder}
      <Modal
        title={title}
        open={open}
        onOk={handleOk}
        onCancel={cancel}
        footer={[
          <Button key="reload" onClick={() => window.location.reload()}>
            รีโหลด
          </Button>,
          <Button key="cancel" onClick={cancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            ตกลง
          </Button>
        ]}
      >
        <div id={qrcodeRegionId} />
      </Modal>
    </div>
  );
};

export default QrcodeComponent;