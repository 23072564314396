import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const setPatrolService = async (payload) => {
  if (payload.report_message.includes("❌")) {
    payload.report_status = "abnormal";
  }
  const { data } = await supabase.from("patrol").insert(payload);
  return await data;
};

export const getPatrolCycleBySite = async () => {
  const deviceData = JSON.parse(localStorage.getItem("device"));
  const site_uuid = deviceData[1];
  const { data } = await supabase.from("site_patrol_cycle").select("*").eq("site_uuid", site_uuid);
  return await data;
};

export const checkSameCheckpointService = async (payload) => {
  const { data } = await supabase.from("patrol").select("*").eq("checkpoint_uuid", payload.checkpoint_uuid);
  return await data;
};
